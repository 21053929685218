<template>
  <v-container fluid pa-1>
    <v-row no-gutters>
      <v-col cols="8" class="pr-2">
        <v-card flat>
          <v-card-title class="secondary">
            <span>Tariffs</span>
            <v-spacer></v-spacer>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn class="ml-2" icon color="green" v-on="on" dark @click="addTariff">
                  <v-icon dark>add</v-icon>
                </v-btn>
              </template>
              <span>Add tariff</span>
            </v-tooltip>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="tariffs"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              class="elevation-0"
              hide-default-footer
              @page-count="pageCount = $event"
              single-select
              :single-expand="singleExpand"
              :expanded.sync="expanded"
              item-key="id"
              show-expand
            >
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="onEditTariff(item)">mdi-pencil</v-icon>
                <v-icon small @click="toggleDeleteTariffDialog(item)">mdi-delete</v-icon>
              </template>
              <template v-slot:expanded-item="{ headers, item }" class="elevation-0">
                <td :colspan="headers.length" class="elevation-0 white">
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr class="text-center">
                          <th class="text-center">Min Range</th>
                          <th class="text-center">Max Range</th>
                          <th class="text-center">Cost Per kWh</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="rule in item.rules" :key="rule.id" class="text-center">
                          <td>{{ rule.min_range }}</td>
                          <td>{{ rule.max_range == null ? "-" : rule.max_range }}</td>
                          <td>{{ rule.cost_kwh }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </td>
              </template>
              <template
                slot="no-data"
              >No Tariffs available. Please add a tariff by clicking '+' icon.</template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-pagination
              v-if="tariffs.length >= 10"
              class="mt-3"
              light
              v-model="page"
              :length="pageCount"
            ></v-pagination>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="openDeleteTariffDialog" persistent max-width="600">
      <v-card>
        <v-card-title
          class="headline"
        >Deleting the tariff will remove all invoices associated. Are you sure you want to proceed further ?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="onDeleteTariff">Yes, Delete</v-btn>
          <v-btn color="green darken-1" text @click="openDeleteTariffDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { EventBus } from "@/lib/EventBus";
export default {
  name: "manage-tariffs",
  components: {},
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      searchMeter: "",
      singleExpand: false,
      openDeleteTariffDialog: false,
      selectedTariff: {},
      expanded: [],
      filter: "",
      headers: [
        {
          text: "Name",
          align: "center",
          sortable: true,
          value: "name"
        },
        { text: "Description", value: "description", align: "center" },
        // { text: "Currency", value: "currency", align: "center" },
        {
          text: "Default Cost(Per kWh)",
          value: "default_cost",
          align: "center"
        },
        {
          text: "Min Monthly Charge",
          value: "min_monthly_charge",
          align: "center"
        },
        { text: "Actions", value: "actions", align: "center", sortable: false }
        //  { text: '', value: 'data-ta  ble-expand', sortable: false  }
      ]
    };
  },
  computed: {
    tariffs() {
      return this.$store.getters["MDMStore/getterOrgTariffs"];
    }
  },
  beforeCreate() {},
  mounted() {
    this.$store.dispatch({
      type: "MDMStore/getOrgTariffs"
    });
  },
  methods: {
    onEditTariff(item) {
      this.$router.push({ name: "edittariff", params: item });
    },
    addTariff() {
      this.$router.push({ name: "newtariff", params: {} });
    },
    toggleDeleteTariffDialog(tariff) {
      this.selectedTariff = tariff;
      this.openDeleteTariffDialog = true;
    },
    onDeleteTariff() {
      var self = this;
      this.$store
        .dispatch({
          type: "MDMStore/deleteTariff",
          tariffId: self.selectedTariff.id
        })
        .then(result => {
          if (result.data.hasOwnProperty("success") && result.data.success) {
            self.$store.commit("MDMStore/SET_SNACKBAR_DETAILS", {
              text: "Tariff deleted successfully",
              status: "success"
            });
            self.$store.dispatch({
              type: "MDMStore/getOrgTariffs"
            });
          } else {
            self.$store.commit("MDMStore/SET_SNACKBAR_DETAILS", {
              text: "Error in deleting tariff.",
              status: "warning"
            });
          }
          this.openDeleteTariffDialog = false;
        });
    }
  }
};
</script>
<style scoped>
</style>

  