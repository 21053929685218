var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","pa-1":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"8"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"secondary"},[_c('span',[_vm._v("Tariffs")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"icon":"","color":"green","dark":""},on:{"click":_vm.addTariff}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("add")])],1)]}}])},[_c('span',[_vm._v("Add tariff")])])],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.tariffs,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","single-select":"","single-expand":_vm.singleExpand,"expanded":_vm.expanded,"item-key":"id","show-expand":""},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.onEditTariff(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.toggleDeleteTariffDialog(item)}}},[_vm._v("mdi-delete")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"elevation-0 white",attrs:{"colspan":headers.length}},[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',{staticClass:"text-center"},[_c('th',{staticClass:"text-center"},[_vm._v("Min Range")]),_c('th',{staticClass:"text-center"},[_vm._v("Max Range")]),_c('th',{staticClass:"text-center"},[_vm._v("Cost Per kWh")])])]),_c('tbody',_vm._l((item.rules),function(rule){return _c('tr',{key:rule.id,staticClass:"text-center"},[_c('td',[_vm._v(_vm._s(rule.min_range))]),_c('td',[_vm._v(_vm._s(rule.max_range == null ? "-" : rule.max_range))]),_c('td',[_vm._v(_vm._s(rule.cost_kwh))])])}),0)]},proxy:true}],null,true)})],1)]}}])},[_c('template',{slot:"no-data"},[_vm._v("No Tariffs available. Please add a tariff by clicking '+' icon.")])],2)],1),_c('v-card-actions',[(_vm.tariffs.length >= 10)?_c('v-pagination',{staticClass:"mt-3",attrs:{"light":"","length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600"},model:{value:(_vm.openDeleteTariffDialog),callback:function ($$v) {_vm.openDeleteTariffDialog=$$v},expression:"openDeleteTariffDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Deleting the tariff will remove all invoices associated. Are you sure you want to proceed further ?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.onDeleteTariff}},[_vm._v("Yes, Delete")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.openDeleteTariffDialog = false}}},[_vm._v("Cancel")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }